import './App.css';

function App({ child}) {
  return (
    <div className="App">
      {child}
    </div>
  );
}

export default App;
