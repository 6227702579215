import React from 'react'
import '../css/Loader.css'
import { useEffect } from 'react'
import { useNavigate } from 'react-router'

function Loading() {
    const navigat = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            navigat('/query?width=300px&height=168px&action=1&autoPlay=1&mute=1&userID=1&videoID=1')
        }, 1000);
    }, []);
    
    return (
        <div className='LoaderScreen'>
            <div class="loader"></div>
        </div>
    )
}

export default Loading