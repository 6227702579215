import React, { useState, useRef } from 'react'
import { useEffect } from 'react';
import ReactPlayer from 'react-player/lazy'
import { useSearchParams } from 'react-router-dom';
import '../css/VideoPg.css'

function VideoPg() {
  const playerRef = useRef(null);
  const [Loading, setLoading] = useState(true);

  const actions = {
    '0' : 'pause',
    '1' : 'play'
  }

  const muted = {
    '0' : false,
    '1' : true
  }

  const autoPlays = {
    '0' : false,
    '1' : true
  }

  const [videoState, setVideoState] = useState({});
  const [videoUrl, setVideoUrl] = useState(null);
  const [playPauseState, setPlayPauseState] = useState(false);
  const [videoReady, setVideoReady] = useState(false);
  
  const [isReady, setIsReady] = useState(false);


  const [searchParams, setSearchParams] = useSearchParams({ 'width': '100', 'height': '100', 'action' : '0', 'autoPlay' : '0', 'mute' : '0', 'userID' : null, 'videoID' : null });
  const width = searchParams.get('width');
  const height = searchParams.get('height');
  const action = searchParams.get('action');
  const autoPlay = searchParams.get('autoPlay');
  const userID = searchParams.get('userID');
  const videoID = searchParams.get('videoID');
  const mute = searchParams.get('mute');
  function UpdateUrlAction(action, value) {
    const QString = searchParams;
    const result = {};
    QString.forEach((value, key) => {
      result[key] = value;
    });
    result[action] = value;
    setSearchParams({ ...result });
  }

  const CurrentDateTime = () => {
    // return new Date().toLocaleString();
    var currentdate = new Date();
    const dt = currentdate.getDate();
    const dmon = currentdate.getMonth()+1 <= 9 ? "0"+(currentdate.getMonth()+1) : currentdate.getMonth()+1;
    var datetime = dt + "-" + dmon + "-" + currentdate.getFullYear() + " " + currentdate.getHours() + ":" + currentdate.getMinutes() + ":" + currentdate.getSeconds();
    return datetime
  }

  function UpdateVideoState(Key, Data) {
    let SetData = Data;
    function Process (data) {
      const TempState = JSON.parse(JSON.stringify(data));
      if(Key === 'Resume') {
        SetData = TempState['Pause']?.length > 0 ? TempState['Pause'][TempState['Pause']?.length - 1]?.Data : '00:00';
      }
      if ( TempState[Key] ) {
        TempState[Key].push({"Data" : SetData, "date" : CurrentDateTime()});
      } else {
        TempState[Key] = [{"Data" : SetData, "date" : CurrentDateTime()}];
      }
      
      return TempState
    }
    setVideoState((prevState) => Process(prevState))
  }

  function VideoReady(e) {
    setIsReady(true);
    UpdateVideoState('Ready', '00.00');
    setVideoReady(true);
    setLoading(false);
  }

  function VideoStart(e) {
    console.log('Start playing', e);
    UpdateVideoState('Start', '00.00');
  }

  function VideoResume(e) {
    console.log('Resuming playback', e);
    // UpdateUrlAction("Resume")
    UpdateVideoState('Resume', '00.00');
  }

  function VideoProgress(e) {
    console.log('Progress playing', e);
    UpdateVideoState('Progress', e);
  }

  function VideoPause(e) {
    console.log('Pausing playback', e);
    UpdateVideoState('Pause', e.target.currentTime);
  }

  function VideoSeek(e) {
    console.log('Seeking', e);
    UpdateVideoState('Seeking', e);
  }

  function VideoEnd(e) {
    console.log('Ending', 'end');
    UpdateVideoState('End', '00.00');
  }

  // useEffect(() => {
  //   if(action === 'pause') {
  //     setPlayPauseState(false);
  //   } else {
  //     setPlayPauseState(true);
  //   }
  //   console.log(action)
  // }, [action])

  // useEffect(() => {
  //   console.log(autoPlay, autoPlays[autoPlay])
  //   if(autoPlays[autoPlay]) {
  //     setPlayPauseState(true);
  //   }
  // }, [autoPlay])

  // useEffect(() => {
  //   console.log('playPauseState', playPauseState);
    
  // }, [playPauseState])
  
  useEffect(() => {
    if(userID && videoID) {
      setIsReady(true);
      setVideoUrl('https://yecc.sgp1.digitaloceanspaces.com/Courses/DemoCourse/video2/video2.m3u8')
    }
  }, [userID, videoID])
  
  useEffect(() => {
    if(actions[action] === 'pause') {
      setPlayPauseState(false)
    } else {
      setPlayPauseState(true)
    }
  }, [action]);

  useEffect(() => {
    // setTimeout(() => {
      if(videoReady && autoPlays[autoPlay]) {
        setPlayPauseState(true);
        UpdateUrlAction('mute', 0)
      }
    // }, 3000);
  }, [videoReady])
  
  useEffect(() => {
    console.log({videoState})
  }, [videoState, autoPlay])

  return (
    <div className={`${Loading ? 'ShowVideoLoader wrapperContainer' : 'wrapperContainer'}`}>
      {Loading ? <div className='LoaderContainer'><div class="loader2"></div></div> : ''}
      {/* <button onClick={() => UpdateUrlAction('action', 1)}>Play</button>
      <button onClick={() => UpdateUrlAction('action', 0)}>Pause</button> */}
      {
        videoUrl &&
        <ReactPlayer
          ref={playerRef}
          autoPlay={autoPlays[autoPlay]}
          muted={muted[mute]}
          playing={playPauseState}
          progressInterval={5000}
          onReady={VideoReady}
          onStart={VideoStart}
          onPlay={VideoResume}
          onProgress={VideoProgress}
          onPause={VideoPause}
          onSeek={VideoSeek}
          onEnded={VideoEnd}
          height={`${height}`}
          width={`${width}`}
          controls
          pip={false}
          url={videoUrl} />
      }
    </div>
  )
}

export default VideoPg